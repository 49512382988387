import { CDataTable } from "@coreui/react";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../ChartofAcc/Chartofacc.css";

const usersData = [
  {
    id: 0,
    Department: "Marketing",
    Sub_Dept: "Manager",
    Email: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 1,
    Department: "Marketing",
    Sub_Dept: "Manager",
    Email: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 2,
    Department: "Marketing",
    Sub_Dept: "Manager",
    Email: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 3,
    Department: "Marketing",
    Sub_Dept: "Manager",
    Email: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 4,
    Department: "Marketing",
    Sub_Dept: "Manager",
    Email: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 5,
    Department: "Marketing",
    Sub_Dept: "Manager",
    Email: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 6,
    Department: "Marketing",
    Sub_Dept: "Manager",
    Email: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 7,
    Department: "Marketing",
    Sub_Dept: "Manager",
    Email: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 8,
    Department: "Marketing",
    Sub_Dept: "Manager",
    Email: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 9,
    Department: "Marketing",
    Sub_Dept: "Manager",
    Email: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 10,
    Department: "Marketing",
    Sub_Dept: "Manager",
    Email: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
];
function Chartofacc() {
  const fields = [
    { key: "Department", _style: { width: "25%" } },
    { key: "Sub_Dept", _style: { width: "25%" } },

    { key: "Email", _style: { width: "25%" } },
    { key: "Budget", _style: { width: "25%" } },
  ];
  return (
    <div className="containersty">
      <Container fluid>
        <Row>
          <Col lg="3" className="m-0 p-0">
            <Link to="/department/marketing">
              <div className="singalboxdpt1 ColorCards">
                <div className="bgsti1">
                  <p>Dept.</p>

                  <h6 className="cardheaddingsty">MARKETING</h6>

                  <p className="parasty">
                    List of maketing departments
                    <br />& other information
                  </p>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg="3" className="m-0 p-0">
            <Link to="/department/sales">
              <div className="singalboxdpt2 ColorCards">
                <div>
                  <p>Dept.</p>

                  <h6 className="cardheaddingsty">SALES</h6>

                  <p className="parasty">
                    List of all sales departments
                    <br />& other information
                  </p>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg="3" className="m-0 p-0">
            {" "}
            <Link to="/department/it">
              <div className="singalboxdpt3 ColorCards">
                <div>
                  <p>Dept.</p>

                  <h6 className="cardheaddingsty">IT</h6>

                  <p className="parasty">
                    List of information technology <br /> departments
                  </p>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg="3" className="m-0 p-0">
            <Link to="/department/finance">
              <div className="singalboxdpt4 ColorCards">
                <div>
                  <p>Dept.</p>

                  <h6 className="cardheaddingsty">FINANCE</h6>

                  <p className="parasty">
                    List of all finance departments
                    <br />& other information
                  </p>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>

      {/* table-section */}
      <div className="card p-4 my-4 mobtablesty tablefont">
        <h4 className="py-3 pl-3">Standard Table For Department </h4>
        <div className="px-4">
          <CDataTable
            items={usersData}
            fields={fields}
            itemsPerPage={10}
            pagination
          />
        </div>
      </div>
    </div>
  );
}

export default Chartofacc;
