import {
  faCubes,
  faHandshake,
  faTachometerAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import department from "../assets/img/department1.png";
import group from "../assets/img/group.png";

export default [
  {
    _tag: "MenuItem",
    name: "Chart of Acc",
    to: "/dashboard",

    icon: <FontAwesomeIcon style={{ fontSize: "20px" }} icon={faCubes} />,
  },
  {
    _tag: "MenuItem",
    name: "Org Chart",
    to: "/all-users",

    icon: <FontAwesomeIcon style={{ fontSize: "20px" }} icon={faUsers} />,
  },
  {
    _tag: "MenuItem",
    name: "Vendors",
    to: "/all-vendors",
    icon: <FontAwesomeIcon style={{ fontSize: "20px" }} icon={faHandshake} />,
  },
  // {
  //   _tag: "MenuItem",
  //   name: "User Accounts",
  //   to: "/user-accounts",
  //   icon: <img src={OvalCircle} alt="sidebar-icon" />,
  // },
  // App Settings
  {
    _tag: "SubMenu",
    name: "Settings",
    route: "/settings",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Guest policy",
        to: "/settings/guest-policy",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Cancellation policy",
        to: "/settings/cancellation-policy",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Term & Conditions",
        to: "/settings/term-and-condition",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Genral Information",
        to: "/settings/genral-information",
      },
    ],
  },
];
