import {
  AUTHENTICATE,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
} from "../actionTypes/userActionsType";

const initialState = {
  login: false,
  loading: true,
  userType: "user",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login: true,
        loading: false,
        ...action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };

    case LOGOUT:
      return {
        ...initialState,
        login: false,
        loading: false,
      };
    case AUTHENTICATE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default userReducer;
