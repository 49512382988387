import axios from "axios";

const BASE_URL = "https://us-central1-neofin.cloudfunctions.net/neofin/";
const Api = axios.create({
  baseURL: `${BASE_URL}`,
});

export const setToken = (token) => {
  Api.defaults.headers.common["token"] = `${token}`;
  Api.defaults.headers.common["Accept"] = `application/json`;
  Api.defaults.headers.common["Content-Type'"] = `application/json`;
  Api.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
};

export default Api;
