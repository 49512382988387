import React, { useState } from "react";
import TheContent from "./TheContent";
import TheFooter from "./TheFooter";
import Theheader from "./Theheader";
import TheSidebar from "./TheSidebar";

export default function TheLayout(props) {
  const [SidebarToggle, setSidebarToggle] = useState(false);

  React.useEffect(() => {
    if (window.innerWidth < 450) {
      setSidebarToggle(true);
    }
  }, []);

  const ToggleSideBar = () => {
    setSidebarToggle(!SidebarToggle);
  };
  return (
    <>
      <div>
        <div className="Layout d-flex">
          <TheSidebar {...props} CollapseBar={SidebarToggle} />

          <div className="ContentWrapper">
            <Theheader {...props} ToggleSideBar={ToggleSideBar} />
            <div className="MainContentBlock">
              <TheContent {...props} />
            </div>
            {/* <TheFooter {...props} /> */}
          </div>
        </div>
      </div>
    </>
  );
}
