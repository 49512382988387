import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Nav, Navbar, Button, Form, Row, Col } from "react-bootstrap";
import Home from "../assets/img/home.png";
import {
  faBars,
  faChevronDown,
  faHome,
  faSignOutAlt,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Auth from "../Services/Auth";
import profileImg from "../assets/img/profile.jpg";
const Theheader = (props) => {
  return (
    <>
      <Navbar className="TopNavFixed" bg="white" expand="lg">
        <Button
          onClick={() => {
            props.ToggleSideBar();
          }}
          variant="link"
        >
          {/* <img src={BarImage} alt="toggle" /> */}
        </Button>

        <Navbar.Collapse id="basic-navbar-nav ">
          <Nav className="ml-auto align-items-center">
            <Link className={"nav-link"} to="/dashboard">
              {/* <img src={BellImg} alt="toggle" /> */}
            </Link>

            <Link className={"nav-link mx-2"} to="/dashboard">
              <img src={Home} style={{ width: "20px" }} />
            </Link>

            <div className="d-flex align-items-center">
              <div className="SidebarAvtar" style={{ width: 25, height: 25 }}>
                <img
                  style={{ borderRadius: "50%" }}
                  className="img-fluid"
                  alt="Profile-img"
                  src={profileImg}
                />
                <div className="Online-Dot"></div>
              </div>
              <p className="profile-tab-client-heading m-3">
                {/* {userProfile.personalDetail.name} */}
                {"Person Name"}
              </p>
              <FontAwesomeIcon
                style={{ fontSize: 10, color: "#BBBBBB" }}
                icon={faChevronDown}
                className="mx-2"
              />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Theheader;
