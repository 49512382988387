import Api from "../../Services/Api";
import { APIURLS } from "../../Services/APIURLS";
import {
  AUTHENTICATE,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
} from "../actionTypes/userActionsType";

export const userLogin = (payload) => ({
  type: LOGIN,
  payload: payload,
});

export const userLogout = () => ({
  type: LOGOUT,
});

export const userAuthencate = () => ({
  type: AUTHENTICATE,
});

export const updateUser = (payload) => ({
  type: UPDATE_USER,
  payload: payload,
});

export const userThunkLogin = (token, userType, UserId) => {
  return async function (dispatch) {
    try {
      dispatch(userAuthencate());
      await localStorage.setItem("token", token);
      await localStorage.setItem("userType", userType);
      var res = await Api.get(`${APIURLS.GET_USERPROFILE}/${UserId}`);
      var data = res.data;
      if (data.status !== "success") {
        dispatch(userLogout());
        return;
      }
      var payload = {
        userType: userType,
        ...data.data,
      };
      dispatch(userLogin(payload));
    } catch (error) {
      console.log("OOPS!", error);
    }
  };
};

export const checkAuthenticate = () => {
  return async function (dispatch) {
    try {
      var token = await localStorage.getItem("token");
      if (token === null || token == undefined || token === "") {
        dispatch(userLogout());
      } else {
        var userType = await localStorage.getItem("userType");
        var res = await Api.get(`${APIURLS.GET_USERPROFILE}/${token}`);
        var data = res.data;

        if (data.status !== "success") {
          dispatch(userLogout());
          return;
        }
        // Check User Mobile is active and verified
        var payload = {
          userType: userType,
          ...data["data"],
        };
        dispatch(userLogin(payload));
      }
    } catch (e) {
      console.log("Error ", e);
      dispatch(userLogout());
    }
  };
};

export const updateUserThunk = () => {
  return async function (dispatch) {
    try {
      var token = await localStorage.getItem("token");
      if (token == null || token == undefined || token == "") {
        dispatch(userLogout());
      } else {
        var usertype = await localStorage.getItem("userType");
        var formData = new FormData();
        formData.append("token", token);
        var res = await Api.post("/user/get_profile", formData);
        var data = res.data;
        if (data.status !== "success") {
          dispatch(userLogout());
          return;
        }
        var payload = {
          userType: usertype,
          ...data["data"],
        };
        dispatch(userLogin(payload));
      }
    } catch (e) {
      dispatch(userLogout());
    }

    // Checking
  };
};

export const logout = () => {
  return function (dispatch) {
    localStorage.removeItem("token");
    localStorage.removeItem("userType");
    dispatch(userLogout());
  };
};
