import { CDataTable } from "@coreui/react";
import React from "react";
import { Button, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "../Deptmarketing/Deptmarketing.css";
const usersData = [
  {
    id: 0,
    Department: "Marketing",

    Manager: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 1,
    Department: "Marketing",

    Manager: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 2,
    Department: "Marketing",

    Manager: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 3,
    Department: "Marketing",

    Manager: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 4,
    Department: "Marketing",

    Manager: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 5,
    Department: "Marketing",

    Manager: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 6,
    Department: "Marketing",

    Manager: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 7,
    Department: "Marketing",

    Manager: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 8,
    Department: "Marketing",

    Manager: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 9,
    Department: "Marketing",

    Manager: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
  {
    id: 10,
    Department: "Marketing",

    Manager: "xyz@gmail.com",
    Budget: "<Rs. 10,00,000",
  },
];
function Deptmarketing(props) {
  let Department = useParams();

  const fields = [
    { key: "Department", _style: { width: "40%" } },
    { key: "Manager", _style: { width: "40%" } },
    { key: "Budget", _style: { width: "40%" } },
  ];
  return (
    <div className="containersty">
      <Container>
        <div className="marketigdeptTophedding ">
          <div>
            <p
              style={{
                color: "#12d902",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              DEPARTMENT {Department.depart}
            </p>
          </div>
          <Button variant="outline-primary" className="buttonparent">
            Add Chart
          </Button>{" "}
        </div>

        {/* table-section */}
        <div className="card  p-4 my-4 mobtablesty tablefont">
          <h4 className="py-3 pl-3">Standard Table For Department </h4>
          <div className="px-4">
            <CDataTable
              items={usersData}
              fields={fields}
              itemsPerPage={10}
              pagination
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Deptmarketing;
