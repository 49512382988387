import { setToken } from "./Api";

class Auth {
  constructor() {
    let token = localStorage.getItem("_nomut");
    if (token != null) {
      setToken(token);
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {
    localStorage.removeItem("_nomut");
    this.authenticated = false;

    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
