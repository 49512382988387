import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import LoadinFullPage from "../components/FullPageLoading/LoadinFullPage";

import { TheLayout } from "../Containers";
import { checkAuthenticate } from "../redux-store/actions/userAction";
import Auth from "../Services/Auth";

const ProtectedRoute = (props, { component: Component, ...rest }) => {
  // useEffect(() => {
  //   props.checkLogin();
  // }, [props.userData]);

  const userdata = props.userData;

  // if (userdata.loading) {
  // return <LoadinFullPage />;
  // } else {
  return (
    <Route
      {...rest}
      render={(props) => {
        // if (userdata.login) {
        return <TheLayout {...props} />;
        // } else {
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: "/",
        //         state: {
        //           from: props.location,
        //         },
        //       }}
        //     />
        //   );
        // }
      }}
    />
  );
  // }
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkLogin: () => dispatch(checkAuthenticate()),
  };
};
const mapStateToProps = (state) => ({
  userData: state.userReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
