import Allvendors from "./pages/Addvendor/Allvendors";
import AllUsers from "./pages/AllUsers/AllUsers";
import Chartofacc from "./pages/ChartofAcc/Chartofacc";
import Deptmarketing from "./pages/Deptmarketing/Deptmarketing";

const routes = [
  { path: "/dashboard", name: "Chart Of Accounts", component: Chartofacc },
  {
    path: "/department/:depart",
    name: "Departments",
    component: Deptmarketing,
  },
  {
    path: "/all-users",
    name: "AllUsers",
    component: AllUsers,
  },

  {
    path: "/all-vendors",
    name: "Vendors",
    component: Allvendors,
  },
];

export default routes;
