import { CDataTable } from "@coreui/react";
import React from "react";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalAddvendor from "../../components/Modal/ModalAddvendor";

import "../Deptmarketing/Deptmarketing.css";
const usersData = [
  {
    ID: 0,
    User_Name: "test_user1",

    Email: "test_user1@neofin",
    Phone: "+91-1122-345-678",
    COA: "Marketing",
  },
  {
    ID: 1,
    User_Name: "test_user2",

    Email: "test_user2@neofin",
    Phone: "+91-1122-345-678",
    COA: "IT",
  },
  {
    ID: 2,
    User_Name: "test_user3",

    Email: "test_user3@neofin",
    Phone: "+91-1122-345-678",
    COA: "sales",
  },
  {
    ID: 3,
    User_Name: "test_user4",

    Email: "test_user4@neofin",
    Phone: "+91-1122-345-678",
    COA: "Finance",
  },
  {
    ID: 4,
    User_Name: "test_user5",

    Email: "test_user5@neofin",
    Phone: "+91-1122-345-678",
    COA: "Marketing",
  },
  {
    ID: 5,
    User_Name: "test_user6",

    Email: "test_user6@neofin",
    Phone: "+91-1122-345-678",
    COA: "IT",
  },
  {
    ID: 6,
    User_Name: "test_user7",

    Email: "test_user7@neofin",
    Phone: "+91-1122-345-678",
    COA: "Sales",
  },
  {
    ID: 7,
    User_Name: "test_user8",

    Email: "test_user8@neofin",
    Phone: "+91-1122-345-678",
    COA: "Finance",
  },
  {
    ID: 8,
    User_Name: "test_user9",

    Email: "test_user9@neofin",
    Phone: "+91-1122-345-678",
    COA: "Marketing",
  },
  {
    ID: 9,
    User_Name: "test_user10",

    Email: "test_user10@neofin",
    Phone: "+91-1122-345-678",
    COA: "IT",
  },
  {
    ID: 10,
    User_Name: "test_user1",

    Email: "test_user1@neofin",
    Phone: "+91-1122-345-678",
    COA: "Sales",
  },
];
function Allvendors() {
  const fields = [
    { key: "ID", _style: { width: "20%" } },
    { key: "User_Name", _style: { width: "20%" } },
    { key: "Email", _style: { width: "20%" } },
    { key: "Phone", _style: { width: "20%" } },
    { key: "COA", _style: { width: "20%" } },
  ];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(!show);
  return (
    <div className="containersty">
      <Container>
        <div className="marketigdeptTophedding justify-content-end">
          <Button className="Exportvendorsbtnsty m-2">Export Vendors</Button>
          <Link
            onClick={() => {
              setShow(true);
            }}
          >
            <Button variant="outline-primary" className="buttonparent m-2">
              Add Vendor
            </Button>
          </Link>
        </div>

        {/* table-section */}
        <div className="card  p-4 my-4 mobtablesty tablefont">
          <h4 className="py-3 pl-3">Standard Table For Department </h4>
          <div className="px-4">
            <CDataTable
              items={usersData}
              fields={fields}
              itemsPerPage={10}
              pagination
            />
          </div>
        </div>
      </Container>
      <ModalAddvendor show={show} handleClose={handleClose} />
    </div>
  );
}

export default Allvendors;
