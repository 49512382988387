import React from "react";
import "../Modal/ModalAdduser.css";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
function ModalAddvendor(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <div className="modaladduser">
          <div>
            <Modal.Body>
              <div>
                <h3 className="pb-4">Add Vendor Form</h3>
                <div className="form-group">
                  <div className="inputsty">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      className="form-control"
                    />
                  </div>
                  <div className="inputsty">
                    <input
                      type="text"
                      name="email"
                      placeholder="Eamil"
                      className="form-control"
                    />
                  </div>
                  <div className="inputsty">
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      className="form-control"
                    />
                  </div>

                  <div className="inputsty">
                    <input
                      type="text"
                      name="coa"
                      placeholder="COA"
                      className="form-control"
                    />
                  </div>

                  <div className="inputsty">
                    <input
                      type="text"
                      name="password"
                      placeholder="Password"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div>
                <Link
                  className="btnsty"
                  to="/"
                  // style={{ padding: "10px", background: "#4673DE !important" }}
                >
                  Submit
                </Link>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalAddvendor;
