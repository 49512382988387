import React from "react";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../pages/AllUsers/AllUsers.css";
import ModalAdduser from "../../components/Modal/ModalAdduser";
import profileImg from "../../assets/img/profile.jpg";

function AllUsers() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(!show);
  return (
    <>
      <div className="containersty">
        <div className="marketigdeptTophedding justify-content-end">
          <div className="buttonparent">
            <Link
              onClick={() => {
                setShow(true);
              }}
            >
              <Button variant="outline-primary" className="buttonparent">
                Add User
              </Button>
            </Link>
          </div>
        </div>

        <div className="container">
          <div className="profile-img-container1">
            <div>
              <img src={profileImg} className="profile-img" />
            </div>
          </div>
          {/* <FontAwesomeIcon icon={} /> */}
          <div className="profile-img-container1">
            <div className="profilenameperson">
              <h6 className="personnamesty">Person Name</h6>
              <p className="personemail">personname@neofin.com</p>
            </div>
          </div>
          <div className="Topprofile-container">
            <div className="vatical-line"></div>
          </div>

          <div className="line-container">
            <div className="circle-container">
              <div className="circle-user"></div>
              <div className="circle-user"></div>
              <div className="circle-user"></div>
            </div>
          </div>
          <div className="mx-5">
            <div className="line-input "></div>
          </div>

          <div className="vartical-line-container">
            <div className="vatical-line"></div>
            <div className="vatical-line"></div>
            <div className="vatical-line"></div>
          </div>

          <div className="profile-img-container">
            <div className="">
              <img src={profileImg} className="profile-img" />
            </div>
            <div className="">
              <img src={profileImg} className="profile-img" />
            </div>
            <div className="">
              <img src={profileImg} className="profile-img" />
            </div>
          </div>
          <div className="person-name-container">
            <div className="profilenameperson">
              <h6 className="personnamesty">Person Name</h6>
              <p className="personemail">personname@neofin.com</p>
            </div>
            <div className="profilenameperson">
              <h6 className="personnamesty">Person Name</h6>
              <p className="personemail">personname@neofin.com</p>
            </div>
            <div className="profilenameperson">
              <h6 className="personnamesty">Person Name</h6>
              <p className="personemail">personname@neofin.com</p>
            </div>
          </div>
          <div className="profile-img-container1">
            <div className="">
              <div className="vatical-line"></div>
              <div className="circle-user1 "></div>
            </div>
          </div>

          <div className="profile-img-container1">
            <div>
              <img src={profileImg} className="profile-img" />
            </div>
          </div>
          <div className="profile-img-container1">
            <div className="profilenameperson">
              <h6>Person Name</h6>
              <p className="personemail">personname@neofin.com</p>
            </div>
          </div>
        </div>
      </div>
      <ModalAdduser show={show} handleClose={handleClose} />
    </>
  );
}

export default AllUsers;
