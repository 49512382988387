import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Container, Form, FormControl } from "react-bootstrap";
import "./Login.css";
import Polygon from "../../assets/img/Polygon.png";
import { Link } from "react-router-dom";
function Login() {
  return (
    <>
      <div className="LoginMainContainer">
        <div className="LoginPageFormContainer">
          <div className="FormBlock">
            <Form>
              <div className="FormGroupBlock">
                <FontAwesomeIcon
                  style={{ color: "#3bccf6", fontSize: 20 }}
                  icon={faEnvelope}
                />
                <Form.Control
                  className="FormInput"
                  type="text"
                  placeholder="Email Id"
                />
              </div>
              <div className="FormGroupBlock">
                <FontAwesomeIcon
                  style={{ color: "#3bccf6", fontSize: 20 }}
                  icon={faLock}
                />
                <Form.Control
                  className="FormInput"
                  type="password"
                  placeholder="Password"
                />
              </div>

              <Link to="/dashboard">
                <Button className="ThemeSubmitBtn mt-3" type="submit">
                  Login
                </Button>
              </Link>
            </Form>
          </div>
        </div>
        <div className="LoginPageLogoContain">
          <div className="ContentLoginPage">
            <h4
              className="TextS"
              style={{
                fontSize: "1.1em",
                fontWeight: "600",
                color: "#ffffffd4",
              }}
            >
              WELCOME TO
            </h4>
            <img
              className="my-4"
              style={{ width: 65, height: 65 }}
              src={Polygon}
            />
            <h2
              className="TextS"
              style={{ fontSize: "1.7em", fontWeight: "600" }}
            >
              NEOFIN
            </h2>
            <p
              className="TextS"
              style={{ fontSize: "15px", letterSpacing: "0.5px" }}
            >
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
