import React from "react";

import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  Menu,
  MenuItem,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import _nav from "./_nav";

import profileImg from "../assets/img/profile.jpg";

import { connect } from "react-redux";

const TheSidebar = (props) => {
  let currentPage = props.location.pathname;
  // let userProfile = props.userData.userProfile;

  return (
    <ProSidebar collapsed={props.CollapseBar} className="SideBar">
      <Link to="/dashboard">
        <h4 className="SideBarheding">{"NeoFin"}</h4>
      </Link>
      <SidebarHeader className="SideBarHeader">
        <div className="SideBarProfiletab">
          <div className="SidebarAvtar">
            <img
              style={{ borderRadius: "50%" }}
              className="img-fluid"
              alt="Profile-img"
              src={profileImg}
            />
          </div>
          <div className="profile-tab-content">
            <p className="profile-tab-client-heading">
              {/* {userProfile.personalDetail.name} */}
              {"Person Name"}
            </p>
            <p className="profile-tab-client-type">
              {/* {userProfile.userType} */}
              {"CEO - Founder"}
            </p>
          </div>
        </div>
      </SidebarHeader>

      <SidebarContent>
        {/* <div className="MenuBreacker">
          <p>Main Menu</p>
        </div> */}
        <Menu iconShape="square">
          {_nav.map((sidebarmenus, index) => {
            if (sidebarmenus._tag === "MenuItem") {
              return (
                <MenuItem
                  className="SidebarMenuItem"
                  key={index.toString()}
                  active={sidebarmenus.to === currentPage ? true : false}
                >
                  {sidebarmenus.name}
                  <Link to={`${sidebarmenus.to}`} />
                  <span className="pro-item-sideicon">{sidebarmenus.icon}</span>
                </MenuItem>
              );
            }
            return <div key={index.toString()} />;
          })}
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        {/**
         *  You can add a footer for the sidebar ex: copyright
         */}
      </SidebarFooter>
    </ProSidebar>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userReducer,
});

export default connect(mapStateToProps)(TheSidebar);
