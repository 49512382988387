import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Chartofacc from "./pages/ChartofAcc/Chartofacc";

import ProtectedRoute from "./Authentication/Protected.route";
import Deptmarketing from "./pages/Deptmarketing/Deptmarketing";
import AllUsers from "./pages/AllUsers/AllUsers";
import Allvendors from "./pages/Addvendor/Allvendors";
import Login from "./pages/LoginPage/Login";

function App() {
  return (
    <Router>
      <Switch>
        <Route
          path="/"
          exact
          name="Login Page"
          render={(props) => {
            return <Login {...props} />;
          }}
        />
        <ProtectedRoute path="/dashboard" component={Chartofacc} />
        <ProtectedRoute path="/department/:depart" component={Deptmarketing} />
        <ProtectedRoute path="/all-users" component={AllUsers} />
        <ProtectedRoute path="/all-vendors" component={Allvendors} />
      </Switch>
    </Router>
  );
}

export default App;
